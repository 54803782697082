<template>
	<div class="av">
		<div class="av__inner d-flex flex-column position-relative">

            <label class="mb-0" for="">{{ $t('select-voices') }}</label>
            <button type="button" :disabled="isGenerate || isPlayingAV" class="pae__choose-voice--button btn d-flex align-items-end" @click="$emit('click:change-avatar')">
				<img :src="avatar?.character.avatarSrc" :alt="avatar?.character.name" class="pae__choose-voice--image">
				<div class="d-flex align-items-center">
					<p :title="avatar?.character.name" v-if="avatar?.character.isUseModel && !avatar?.character?.isMultilingual" class="pae__choose-voice--label m-0">{{ truncatedName }} (Clone) <span v-if="avatar.character.type == 'multilingual'">- {{ capitalizeText(avatar.character.type) }}</span> <span v-else>- {{ avatar.language.name }}</span></p>
					<p class="pae__choose-voice--label m-0" v-else>{{ avatar?.character.name }} - {{ avatar.language.name }}</p>
					<img src="@/assets/images/icons/ic_chevron-bottom.svg" alt="" class="ml-4">
				</div>
			</button>


            <div class="d-flex align-items-center justify-content-between">
                <label class="mb-0" for="voice-text">{{ $t('input-text') }}</label>

                <div class="d-flex align-items-center">
                    <button class="btn button--play__voice mr-2 d-flex align-items-center" @click="$emit('click:handle-play-audio')" :disabled="!playAudio"><font-awesome-icon class="mr-2" :icon="`fa-solid ${isPlayingAV ? 'fa-pause' : 'fa-play'}`"/>{{ isPlayingAV ? $t('pause') : $t('play')}}</button>
                    <button class="btn button--download d-flex align-items-center" :disabled="!playAudio" @click="$emit('click:handle-download')">
                        <svg width="15" height="15" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.25 13.041L10.25 1" :stroke="playAudio ? '#6D75F6' : '#585859'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class=""/>
                            <path d="M13.168 10.1133L10.252 13.0413L7.33597 10.1133" :stroke="playAudio ? '#6D75F6' : '#585859'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M14.883 5.73437H15.816C17.851 5.73438 19.5 7.38337 19.5 9.41937V14.3034C19.5 16.3334 17.855 17.9784 15.825 17.9784L4.685 17.9784C2.65 17.9784 1 16.3284 1 14.2934V9.40838C1 7.37937 2.646 5.73437 4.675 5.73437H5.617" :stroke="playAudio ? '#6D75F6' : '#585859'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
            </div>
            <textarea v-model="formData.text" maxlength="5000" class="form-control" id="voice-text" rows="8"></textarea>

            <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <button class="btn btn--generate px-4" :disabled="formData.text.length == 0 || isGenerate || playAudio" @click="$emit('click:generate-voice')">{{ isGenerate ? 'Loading...' : 'Generate' }}</button>
                <h6 class="title__voice-studio ml-4" @click="$router.push({ name : 'Generate-Videos', params : { mode : 'new', id : '1' } })">{{ $t('open-voice-studio') }} <i class="bi bi-arrow-right "></i></h6>
                </div>

                <div class="av-header__subscription position-relative d-flex align-items-center dropdown">
                    <img :src="require('@/assets/images/icons/ic_subscription_info.svg')" height="15" alt=""/>
                    <div class="av-header__tooltip dropdown-menu">
                        <span>{{ $t('audio-duration-left') }} <b class="cursor-pointer av__tooltip--topup" @click="$router.push({name: 'TopUp'})">{{ $t('top-up-more') }}</b></span>
                        <div class="av__tooltip--package d-flex align-items-center mt-2">
							<div class="av__tooltip--package-wrapper p-2">
								<div class="av__tooltip--package-wrapper-inner d-flex align-items-start">
									<div class="av__tooltip--package-quota d-flex flex-column">
										<p class="m-0 tooltip--package__text">Basic</p>
										<p class="m-0 tooltip--package__quota">{{ allocation?.audio_generates_quota || '0' }}<span class="tooltip--package__text"> {{ $t('mins') }}</span></p>
									</div>
								</div>
							</div>
							<div class="av__tooltip--package-wrapper p-2">
								<div class="av__tooltip--package-wrapper-inner d-flex align-items-start">
									<div class="av__tooltip--package-quota d-flex flex-column">
										<p class="m-0 tooltip--package__text">Multilingual</p>
										<p class="m-0 tooltip--package__quota">{{ allocation?.audio_multilingual_generates_quota || '0' }}<span class="tooltip--package__text"> {{ $t('mins') }}</span><span class="tooltip--package__exp ml-3" v-if="allocation?.expired_date && allocation?.audio_multilingual_generates_quota">exp. {{ getMonthDate(allocation?.expired_date) }}</span></p>
									</div>
								</div>
							</div>
						</div>
                    </div>
                    <p class="av-header__subscription-text m-0">{{ load_alloc ? "Loading..." : (allocation?.audio_generates_quota + allocation?.audio_multilingual_generates_quota).toFixed(2) +" "+ $t('mins') }}</p>
                </div>
            </div>

		</div>
	</div>
</template>

<script>
import { AlertUtils } from "@/mixins/AlertUtils";

export default {
	mixins: [AlertUtils],
	props: {
        avatar: {
			type: Object,
			default: () => {
				return {}
			}
		},
        isGenerate: {
			type: Boolean,
			default: false
		},
        load_alloc: {
			type: Boolean,
			default: false
		},
        playAudio: {
			type: Boolean,
			default: false
		},
        isPlayingAV: {
			type: Boolean,
			default: false
		},
        allocation: {
            type: Object,
            default : () => {
                return null
            }
        },
        value: {
			type: Object,
			default: () => {
				return {}
			}
		},
	},
	data() {
		return {
            formData: this.value,
		}
	},
	computed: {
		truncatedName() {
			const maxLength = 20;
			const ellipsis = '...';

			return this.avatar?.character.name.length > maxLength ? this.avatar?.character.name.substring(0, maxLength - ellipsis.length) + '...' : this.avatar?.character.name;
		},
	},
    watch: {
        value: {
			handler(newValue) {
				this.formData = newValue
			},
			deep: true
		},
		formData: {
			handler(newValue) {
				this.$emit('input', newValue)
			},
			deep: true
		},
    },
	methods: {
		capitalizeText(text) {
			return text.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
		},
        getMonthDate(dateTime) {
			const months = [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			];

			const dateObject = new Date(dateTime);
			const tanggal = dateObject.getUTCDate();
			const monthIndex = dateObject.getUTCMonth();
			const month = months[monthIndex];

			return `${month} ${tanggal}`;
		},

	},
	mounted() {
	},
	beforeDestroy() {
	},
}
</script>

<style scoped>
label{
	font-size: 13px;
}
.av-header__subscription {
  gap: 6px;
}
.av-header__subscription:hover .av-header__tooltip {
  display: block;
}

.av-header__tooltip {
	position: absolute;
left: -236px !important;
top: -25px;
  width: auto !important;
  background-color: #1F1F1F;
  color: #ffffff;
  font-size: 12px;
  padding: 10px 15px;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
}
.av__tooltip--topup {
	color: #6D75F6;
}

.av__tooltip--package {
	gap: 6px;
}

.av__tooltip--package-wrapper {
	border-radius: 4px;
	background: #2D2D2D;
}

.av__tooltip--package-wrapper-inner {
	gap: 4px;
}
.tooltip--package__text {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.av-header__subscription-text {
  font-size: 13px;
  font-weight: 500;
}

.tooltip--package__quota {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.av__tooltip--package-quota {
	gap: 4px;
}

.tooltip--package__exp {
	color: #8C8C8C;
	font-family: Avenir Next;
	font-size: 8px;
	font-style: italic;
	font-weight: 500;
	line-height: normal;
}

.button--download{
    padding-top: 9px;
    padding-bottom: 8px;
}
.button--play__voice:disabled{
	color: #585859;
}
.button--play__voice, .button--download{
    border-radius: 3px;
    background: #2D2D2D;
    color: #fff;
}
.title__voice-studio:hover{
    color: #545EED;
}
.btn--generate:hover{
    background-color: #545EED;
}
.btn--generate{
    border-radius: 4px;
    background-color: #6D75F6;
    color: #fff;
    transition: background-color 0.25s;
}
.title__voice-studio{
    color: #6D75F6;
    font-family: 'Avenir Next';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    transition: color 0.25s;
}
.pae__choose-voice--button {
	border-radius: 4px;
	border: 1px solid #2D2D2D;
	gap: 4px;
    width: fit-content;
}
.pae__choose-voice--label {
	font-size: 12px;
	color: #fff;
}
.pae__choose-voice--image {
	width: 20px;
	height: 20px;
	border-radius: 50%;
}
.av {
	padding: 2px;
	background-image: linear-gradient(to bottom right, #FFFFFF33, #FFFFFF11);
	border-radius: 8px;
}

.av__inner {
	background: linear-gradient(0deg, #121418 0%, #121418 100%), rgba(49, 48, 54, 0.30);
	border-radius: 8px;
	backdrop-filter: blur(2px);
	gap: 10px;
	padding: 22px;
}

.cxav__inner--padding {
	padding-left: 22px;
	padding-right: 22px;
}

.av__icon-info {
	right: 6px;
	top: 6px;
}
.form-control, .form-control:focus{
    background: transparent;
    color: #FFF;
    font-family: Avenir Next;
    font-size: 14px;
}

.av__info--inner {
	left: 0;
	width: 350px;
	border-radius: 6px;
	background-color: #1F1F1F;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);

	visibility: hidden;
	opacity: 0;
	transition: opacity 0.25s;
}


</style>