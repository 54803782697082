<template>
    <div class="tutorials container-fluid">
        <h6 class="title pl-3 pb-3">{{ $t('tutorial--p2') }}</h6>
        <TemplatesHome 
            :hideScrollButton="hideScrollButton" 
            @handleScroll="handleScroll" 
            :videos="videoTutorial" 
            @show-button="(newVal) => showButtonScroll = newVal" 
            @click:play-video="handleClickPlayVideo
        "/>

        <b-modal id="show-video" centered header-class="border-0 pt-4 pb-0" no-close-on-backdrop :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="xl" style="background-color: rgba(0, 0, 0, 0.5) !important; backdrop-filter: blur(5px) !important;" @hidden="url = null">
            <div class="d-flex align-items-center justify-content-end mt-2 mb-1">
                <div class="text-right mb-3">
                    <button class="btn btn-close d-flex justify-content-center align-items-center" @click="$bvModal.hide('show-video')"><b-icon icon="x"></b-icon></button>
                </div>
            </div>
            <label  class="w-100 d-flex flex-column align-items-center text-center">
                <div class="h-100 d-flex align-items-center">
					<iframe width="840" height="500" :src="url" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </label>
            <div class="d-flex align-items-center justify-content-center mt-3">
                <button @click="$router.push({ name:'Generate-Videos', params: { mode: 'new', id: '1' } })" class="btn btn--create-video text-white d-flex align-items-center justify-content-center"><img src="@/assets/images/plus-white.svg" alt="Create new" class="mr-1"> {{ $t('create-new') }}</button>
            </div>
        </b-modal>
        <Soca v-if="isGetVideo"/>
    </div>
</template>

<script>
import TemplatesHome from '@/components/home/templates'
import Soca from '@/components/loading/Soca'
import { getMasterVideoTutorial } from '@/services/master-video/master-video.service'

import $ from 'jquery';
export default {
    components: {
        TemplatesHome,
        Soca
    },
    data(){
        return{
            hideScrollButton: false,
            videoTutorial: [],
            isGetVideo: false,
            showButtonScroll: true,
            url: null
        }
    },
    methods: {
        handleClickPlayVideo(url) {
			this.url = url
			if(this.url) {
				this.$bvModal.show('show-video')
			}
		},
        async getVideo() {
			this.isGetVideo = true
			await getMasterVideoTutorial()
			.then((response) => {
				if(response.status) {
                    response.data.forEach(el => {
                        this.videoTutorial.push({
                            ...el,
                            load: true
                        })
                    });
					
					this.isGetVideo = false
				} else {
					this.isGetVideo = false
				}
			})
			.catch((error) => {
				console.error(error)
				this.isGetVideo = false
			})
		},
        handleScroll(){

            let windowHeight = window.innerHeight;

            let scrollY = window.scrollY;

            let documentHeight = document.documentElement.scrollHeight;

            if (scrollY + windowHeight >= documentHeight) {
                this.hideScrollButton = true;
            }


            window.scrollBy(0, 250);

        },
    },
    created(){
		window.addEventListener('scroll', this.handleShowButton)
	},
	destroyed(){
		window.addEventListener('scroll', this.handleShowButton)
	},
    handleShowButton(){
			let windowHeight = window.innerHeight;

			let scrollY = window.scrollY;

			let documentHeight = document.documentElement.scrollHeight;

			// console.log("windowHeight : "+ windowHeight)
			// console.log("scrollY : "+scrollY)
			// console.log("documentHeight : "+documentHeight)
            console.log("SCROLLED")
            
			if (scrollY + windowHeight >= documentHeight) {
				this.hideScrollButton = true;
			}else{
				this.hideScrollButton = false;
			}
	},
    mounted(){
        $('.main-container-fluid').addClass('ml-0');
        this.getVideo();
    },
    beforeDestroy() {
        $(".main-container-fluid").removeClass('ml-0');
    },
}
</script>

<style scoped>
.tutorials .title{
    color: #FFF;
    font-family: 'Avenir Next';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
}
.tutorials{
    padding-left: 1.7rem;
}

.btn--create-video {
    background-color: #6D75F6;

    transition: background-color 0.25s;
}

.btn--create-video:hover {
    background-color: #545EED;
}
</style>