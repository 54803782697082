<template>
	<div class="templates position-relative">
		<div class="t__inner container-fluid t__inner--hide-shadow h-100 overflow-y-auto container-fluid px-0" ref="scrollableList" @scroll="handleGradientVisibility" :class="{'t__inner--show-top-shadow container-fluid px-0': showTopShadow, 't__inner--show-bottom-shadow container-fluid px-0': showBottomShadow}" >
			<div class="pr--template" id="template">
				<div v-for="(data, index) in videos" :key="index" class="t__col position-relative">
					<!-- <video v-show="data.play" width="100%" controlsList="nodownload" :ref="'video' + index" :poster="data.img">
                        <source :src="data.video" type="video/mp4">
                    </video> -->
					<div class="p-2 ti__content cursor-pointer" @click="playTutorial(data.url)" v-show="!data.load">
						<img :src="data.thumbnail" alt="Templates" :ref="'img' + index" width="250" @load="onImageLoad(index)">
						<div class="t__action-play">
							<button
								type="button"
								class="t__toggle btn p-0 rounded-0"
							>
								<font-awesome-icon
									icon="fa-solid fa-play-circle"
									class="t__toggle-icon"
								/>
							</button>
						</div>
						<div class="t__action d-flex align-items-center justify-content-between">
							<p class="m-0 ml-2 title--video" style="line-height: 15px;" :title="data.title">{{ data.title }}</p>
							<p class="badge__durations mb-0 mr-1" >{{ data.duration }}</p>
						</div>
					</div>
					<b-skeleton v-if="data.load" class="h-100"></b-skeleton>
				</div>
			</div>
		</div>

		<!-- <button class="h__button-scroll btn d-flex align-items-center mx-auto" :class="{ 'h__button-scroll-hidden' : hideScrollButton }" @click="handleScrollDown()">
			<p class="m-0">Scroll to get it inspired!</p>
			<font-awesome-icon icon="fa-solid fa-arrow-down" />
		</button> -->


		<!-- MODAL SHOW VIDEO -->
	</div>
</template>

<script>
// import VueYoutube from 'vue-youtube'
export default {
	components: {
		// VueYoutube
	},
	props: {
		// scrollPosition: {
		// 	type: Number,
		// 	default: 0
		// },
		hideScrollButton: {
			type: Boolean,
			default: false
		},
		videos: {
			type: Array,
			default: () => {
				return []
			}
		}
	},
	watch: {
		scrollPosition(newVal) {
			this.scrollPage(newVal)
		},

		showBottomShadow(newVal) {
			this.$emit('show-button', newVal)
		}
	},
	data() {
		return {
			showTopShadow: false,
			showBottomShadow: true,
			currentID: null,
			templates : [
				{
					thumbnail : require('../../../assets/images/home/template_dummy-3.png'),
					video : 'https://stg-ml.soca.ai/static/audio/633529b6089c3acaf046bf22/64901f9cf4a9d515eaa4a5d5/final.webm?v=0.474512157507732',
					title: 'How to use - AI Voices',
					play: false,
					duration: "2:38",
					load: true,
					url: 'https://www.youtube.com/embed/H-IbGjweMLA'
				},
				{
					thumbnail : require('../../../assets/images/home/template_dummy-2.png'),
					video : 'https://stg-ml.soca.ai/static/audio/633529b6089c3acaf046bf22/64901f9cf4a9d515eaa4a5d5/final.webm?v=0.474512157507732',
					title: 'How to use - AI Speech Editor',
					play: false,
					duration: "1:26",
					load: true,
					url: 'https://www.youtube.com/embed/2DM95GhYW1o'
				},
				{
					thumbnail : require('../../../assets/images/home/template_dummy-5.png'),
					video : 'https://stg-ml.soca.ai/static/audio/633529b6089c3acaf046bf22/64901f9cf4a9d515eaa4a5d5/final.webm?v=0.474512157507732',
					title: 'How to use - Personal Voices',
					play: false,
					duration: "2:12",
					load: true,
					url: 'https://www.youtube.com/embed/l9_WO1HLSSo'
				},
				{
					thumbnail : require('../../../assets/images/home/template_dummy-4.png'),
					video : 'https://stg-ml.soca.ai/static/audio/633529b6089c3acaf046bf22/64901f9cf4a9d515eaa4a5d5/final.webm?v=0.474512157507732',
					title: 'How to use - Import Script',
					play: false,
					duration: "1:07",
					load: true,
					url: 'https://www.youtube.com/embed/6-dc5EqKCO4'
				},
				{
					thumbnail : require('../../../assets/images/home/image_generator.png'),
					video : 'https://stg-ml.soca.ai/static/audio/633529b6089c3acaf046bf22/64901f9cf4a9d515eaa4a5d5/final.webm?v=0.474512157507732',
					title: 'How to use - Image Generator',
					play: false,
					duration: "1:20",
					load: true,
					url: 'https://www.youtube.com/embed/xXK1mT3v8sc'
				},
				{
					thumbnail : require('../../../assets/images/home/generate_videos.png'),
					video : 'https://stg-ml.soca.ai/static/audio/633529b6089c3acaf046bf22/64901f9cf4a9d515eaa4a5d5/final.webm?v=0.474512157507732',
					title: 'How to use - Generate Videos',
					play: false,
					duration: "1:45",
					load: true,
					url: 'https://www.youtube.com/embed/pCDnShZsJnQ'
				},
			]
		}
	},

	methods: {
		handleScrollDown(){
			this.$emit('handleScroll');
		},
		playTutorial(url){
			this.$emit('click:play-video', url)
			// this.currentID = id;
			// if(this.currentID) {
			// 	this.$bvModal.show('show-video');
			// }
		},
		handleGradientVisibility() {
			const { scrollTop, scrollHeight, clientHeight } = this.$refs.scrollableList;

			this.showTopShadow = scrollTop > 0;
			this.showBottomShadow = scrollTop < scrollHeight - clientHeight;
		},
		scrollPage(val) {
			const scrollableList = this.$refs.scrollableList;
      		scrollableList.scrollTop += val;
		},
		togglePlayPause(index) {
			const videoElement = this.$refs['video' + index][0];
			const imgElement = this.$refs['img' + index][0];
			const data = this.templates[index];

			if (data.play) {
				videoElement.pause();
			} else {
				videoElement.play();
			}
			videoElement.style.height = imgElement.offsetHeight + 'px';

			data.play = !data.play;
		},
		onImageLoad(index) {
			this.videos[index].load = false
			console.log(this.videos[index])
		}
	},
	mounted() {
		this.handleGradientVisibility();
		window.addEventListener('resize',this.handleGradientVisibility);
	},
	beforeDestroy() {
		window.removeEventListener('resize',this.handleGradientVisibility);
	},
}
</script>

<style>
#show-video .modal-content {
	background-color: transparent !important;
}
</style>

<style scoped>
.title--video{
	color: #FFF;
	font-family: 'Avenir Next';
	font-size: 18.256px;
	font-style: normal;
	font-weight: 600;
}
.pr--template{
	display: grid;
    grid-template-columns: repeat(3,0.3fr)!important;
}
.h__button-scroll {
	position: fixed;
    opacity: 1;
	transform: scale(1) translate(-50%, -50%);
    left: 0;
    right: -260px;
    bottom: 1.5rem;
    gap: 5px;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 15px;
    color: #fff;
    border: 0px solid #fff;
    /* background: rgba(0, 0, 0, 0.3); */
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(3px);
    border-radius: 23px;
    width: -moz-fit-content;
    width: fit-content;
	transition: transform 0.3s ease;
}

.h__button-scroll-hidden {
	transform: scale(0) translate(-50%, -50%);
	transition: transform 0.3s ease;
}
	.btn-close{
        background-color: #EDEDED;
        border-radius: 50%;
        width: 27px;
        height: 27px;
    }

    .btn-impor-custom{
        border: 2px dashed #2D2D2D;
        height: 20rem;
        border-radius: 10px;
    }

    .btn-grey{
        background-color: #6D75F6 !important;
        color: white;
        border: 0px !important;
    }

    .btn-purple {
        background-color: #6D75F6;
        border: 1px solid #6D75F6;
        color: white;
    }

    .progress__upload .progress-bar {
        background-color: #6D75F6;
    }

    .text--small {
        font-size: 12px;
        color: #979797;
    }
.overflow-y-auto{
	overflow-y: auto;
}

.badge__durations{
	border-radius: 6px;
	background: #000;
	color: #fff;
	padding: 2px 7px 2px 7px;
}
.templates {
	height: 100%;
	overflow: auto;
}

.t__inner {
	scroll-behavior: smooth;
	transition: scroll-behavior 0.5s;
}

.t__inner::-webkit-scrollbar-thumb {
	display: none;
}

.t__inner::before {
  opacity: 1;
  visibility: visible;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 25px;
  background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
  transition: opacity 0.25s, visibility 0.25s;
}

.t__inner--hide-shadow::before {
	opacity: 0;
  	visibility: hidden;
}

.t__inner::after {
  opacity: 1;
  visibility: visible;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 25px;
  background-image: linear-gradient(to top, #000000, rgba(0, 0, 0, 0));
  transition: opacity 0.25s, visibility 0.25s;
}

.t__inner--hide-shadow::after {
	opacity: 0;
  	visibility: hidden;
}

.t__row {
	/* line-height: 0;
	-webkit-column-count: 4;
	-webkit-column-gap: 10px;
	-moz-column-count: 4;
	-moz-column-gap: 10px;
	column-count: 4;
	column-gap: 10px; */
}

.t__col {
	margin-bottom: 10px;
	border-radius: 8px;
}

.t__col img {
	width: 100%;
	height: auto;
	border-radius: 20px;
}

.t_-col video {
	border-radius: 8px;
}

.t__inner::before,
.t__inner::after {
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.t__inner--hide-shadow::before,
.t__inner--hide-shadow::after {
	box-shadow: none;
}

.t__inner--hide-shadow.t__inner--show-top-shadow::before {
	opacity: 1;
	visibility: visible;
	z-index: 2;
}

.t__inner--hide-shadow.t__inner--show-bottom-shadow::after {
	opacity: 1;
	visibility: visible;
}

.t__toggle {
  background: none;
  /* filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.20)); */
  /* filter: drop-shadow(13px 18px 14px #000); */
  filter: drop-shadow(13px 7px 14px #000);
}

.t__toggle-icon {
  font-size: 67px;
  color: #FFFFFF;
}

.t__toggle-icon--small {
  font-size: 24px;
}

.t__action {
    width: 100%;
    position: absolute;
    bottom: 7px;
    gap: 10px;
    left: 0;
    padding: 15px 17px;
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0px);
    border-radius: 0px 0px 20px 20px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
}
.text__title_action{
	width: 50%;
	display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
	font-size: 14px;
	font-weight: 600;
}
.t__action-play {
    width: 100%;
    position: absolute;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>

<style>
.ti__content .b-skeleton-img {
	border-radius: 8px;
}
</style>