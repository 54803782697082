<template>
	<div class="home container-fluid d-flex justify-content-center flex-column align-items-center">
		<div class="row d-flex w-100">
			<h3 class="home__title">{{ $t('home') }}</h3>
		</div>
		<div class="home__inner d-flex flex-column w-100 mt-3 pb-4">
			<main class="pr--content">
				<div class="content__middle d-flex h-100">
					<div class="content__left d-flex flex-column">
						<div class="d-flex align-items-center justify-content-between">
							<h3 class="AD__title--home">{{ $t('start-with-aidubbing') }} <p class="mb-2 ml-1" style="font-size: 8px !important;">BETA</p></h3>
							<div class="adp__dropdown dropdown d-flex align-items-center">
								<img
									:src="require('@/assets/images/icons/ic_subscription_info.svg')"
									alt=""
									height="15"
								/>
								<div class="adp__tooltip dropdown-menu">
									<span>{{ $t('video-duration-left') }} <b class="cursor-pointer gv__tooltip--topup" @click="$router.push({ name : 'TopUp' })">{{ $t('top-up-more') }}</b></span>
									<div class="gv__tooltip--package d-flex align-items-center mt-2">
										<div class="gv__tooltip--package-wrapper p-2 w-100">
											<div class="gv__tooltip--package-wrapper-inner d-flex align-items-start">
												<div class="gv__tooltip--package-quota d-flex flex-column align-items-center justify-content-center w-100">
													<p class="m-0 tooltip--package__text">Multilingual</p>
													<p class="m-0 tooltip--package__quota">{{ allocation?.audio_multilingual_generates_quota || '0' }}<span class="tooltip--package__text"> {{ $t('mins') }}</span><span class="tooltip--package__exp ml-3 d-none" v-if="allocation?.expired_date && allocation?.audio_multilingual_generates_quota">exp. {{ getMonthDate(allocation?.expired_date) }}</span></p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<p class="auto-dubbing__subscription-text mb-0 ml-1">{{allocation?.audio_multilingual_generates_quota || '0'}} {{ $t('mins') }}</p>
							</div>
						</div>

						<ADUploadCard
							:queue="totalQueueAutodub"
							:languages="languages"
							:open-lang="openLang"
							v-model="valueCardVideo"
							:list-preview="listVideoPreview"
							@click:play-preview="handleClickPlayPreview"
							:is-submit="isSubmit"
							@duration:type="(val) => typeFileUploaded = val"
							@click:show-modal="checkAccessAD"
						/>
					</div>

					<div class="content__right d-flex flex-column">
						<div class="d-flex align-items-center justify-content-between">
							<h3 class="AV__title--home">{{ $t('start-with-aivoice') }}</h3>
						</div>
						<AiVoicesGeneratorCard
						 :avatar="avatar"
						 :allocation="allocation"
						 @click:change-avatar="$bvModal.show('select-voice-type--aivoice')"
						 v-model="formVoice"
						 :isGenerate="isGenerateVoice"
						 :playAudio="active_play"
						 :isPlayingAV="isPlayingAV"
						 @click:generate-voice="handleGenerateAiVoice"
						 @click:handle-download="handleDownloadAudio"
						 @click:handle-play-audio="playVoiceResult"
						 :load_alloc="load_alloc"
						 />
					</div>
				</div>

				<CardList
					:status_dubb="status_dubb"
					@click:show-info-autodubb="checkAccessAD"
					@click:to-news-reader="toNewsReader"
					:contentRecent="contentRecent"
					:draftsAudio="draftsAudio"
					:recent_load="recent_load"
					:draft_load="draft_load"
					/>

			</main>


			<!-- =================== OLD HOME =======================  -->
			<!-- <BannerHome :data-campaign="dataCampaign" :load-topup="load_topup" @click:buy-now="createInvoice(dataCampaign?._id)" @click:play-video="handleClickPlayVideo"/> -->
			<!-- <div class="d-flex align-items-center justify-content-between mx-3">
				<h4 class="home__subtitle">New in Soca? Watch our latest tutorials!</h4>
			</div> -->
			<!-- <TemplatesHome :hideScrollButton="hideScrollButton" @handleScroll="handleScroll" :videos="videoTutorial" @show-button="(newVal) => showButtonScroll = newVal" @click:play-video="handleClickPlayVideo"/> -->
			<!-- =================== OLD HOME ======================= -->
		</div>

		<AVSelectCharacter
			id="select-voice-type--aivoice"
			:selected-language="avatar?.language"
        	:selected-character="avatar?.character"
			:allocation="allocation"
			@change:voice-type="(newVoiceType) => {
				avatar = newVoiceType,
				$bvModal.hide('select-voice-type--aivoice');
            }"
		/>
		<Soca v-if="isSaving || (valueCardVideo.additionalInclude && isSubmit) || isGenerate"/>
		<ModalUploadAutoDubb
			:queue="totalQueueAutodub"
			:languages="languages"
			:open-lang="openLang"
			v-model="valueCardVideo"
			@click:play-preview="handleClickPlayPreview"
			:is-submit="isSubmit"
			@duration:type="(val) => typeFileUploaded = val"
			@click:reset-value="resetValue"
			@click:handle-get-script="handleGetScript"
			/>
		<ServerErrorModal />
		<ADLangSameDetected @change:open-lang="handleOpenLang"/>
		<QuotaHabisModal />
		<ADNoAudioDetected />
		<div v-if="isSubmit && !valueCardVideo.additionalInclude" class="auto-dub__submit d-flex flex-column align-items-center justify-content-center w-100 h-100 px-5">
			<vue-lottie
				:options="animationOptions"
				:height="300"
			></vue-lottie>
			<div class="progress auto-dub__progress mx-5">
				<div class="progress-bar" role="progressbar" :style="'width:' + progressGetScript + '%'" :aria-valuenow="progressGetScript" aria-valuemin="0" aria-valuemax="100"></div>
			</div>
			<p class="auto-dub__submit--text mb-0 mt-4">Transcribing and translating . . .</p>
		</div>
		<ADPlayPreviewModal id="ad-play-preview" :url="previewUrl"/>
		<AVQuotaHabis />
		<audio ref="AVResult" :src="generatedVoiceDataPath"></audio>
		<ADVideoDurationExceeds :type="typeFileUploaded"/>
		<!-- <ADInfoAcessModal /> -->
		<VCCharacterPackageModal :id="`package-character-${avatar.character.name}`" :package-id="avatar.character.package_id"/>
		<InsuficientQuotaADModal />
		<VoiceServiceUnavailableModal id="voice-service-unavailable-home" :type="avatar?.language?.name == 'Multilingual' ? 'Multilingual' : 'Monolingual'"/>
		<ModalPackageDiscount :list_data_discount="list_data_discount" @click:checkout="createInvoice" :load_topup="load_topup"/>
	</div>
</template>
<script>
// COMPONENT
// import BannerHome from '@/components/home/banner'
// import TemplatesHome from '@/components/home/templates'
import ADUploadCard from "@/components/auto-dubbing/upload-card/Home.vue"
import ModalUploadAutoDubb from "@/components/auto-dubbing/modal/UploadAutoDubb.vue"
import AiVoicesGeneratorCard from "@/components/home/AiVoicesGenerator.vue"
import CardList from "@/components/home/CardList.vue"
import AVSelectCharacter from '@/components/modal/SelectVoiceType';
import Soca from '@/components/loading/Soca'
import ServerErrorModal from '@/components/modal/ServerError';
import ADLangSameDetected from "@/components/modal/TargetLangSame.vue"
import QuotaHabisModal from '@/components/modal/QuotaHabis';
import ADNoAudioDetected from "@/components/modal/NoAudioDetected.vue"
import ADPlayPreviewModal from "@/components/modal/YoutubePlayer.vue"
import AVQuotaHabis from "@/components/modal/QuotaHabis.vue"
// import ADInfoAcessModal from "@/components/home/modal/ModalAutoDubbAccess.vue"
import ADVideoDurationExceeds from "@/components/modal/VideoDurationExceeds.vue"
import VCCharacterPackageModal from '@/components/modal/CharacterPackage';
import InsuficientQuotaADModal from '@/components/modal/InsuficientQuoatAD';
import VoiceServiceUnavailableModal from '@/components/modal/VoiceServiceUnavailable';
import ModalPackageDiscount from '@/components/package/ModalPackageDiscount';
import { EventBus } from '@/helper/event-bus.js';
import axios from 'axios';


import $ from "jquery"

import { getPackageCampaignNew, topupSwc } from '@/services/subscriptions/subscriptions.service'
import { getMasterVideoTutorial } from '@/services/master-video/master-video.service'
import { subscriptionsStatus } from '@/services/subscriptions/subscriptions.service'
import { base_url_machine_learning, base_url_auto_dubbing, base_url_asset } from '@/config/base_url';
import { generateScriptAutodub, postAutodub, uploadFileByChunks, getScriptAutodub, getQueueAutodub } from '@/services/auto-dub/autodub.service';
import { AlertUtils } from "@/mixins/AlertUtils";
import { exportUtils } from "@/mixins/ExportUtils";
import { getJobStatus } from '@/services/generative-ai-text-to-audio/generative-ai-text-to-audio.service';
import { getMasterLanguagesAutodub } from '@/services/master-languages/master_languages.service';
import { useVoiceModel } from '@/services/ttv/ttv.service.js'
import { getGeneratedTextToAudioSSML } from '@/services/generative-ai-text-to-audio/generative-ai-text-to-audio.service'
import { autodubbCheckAccess } from '@/services/auto-dub/autodub.service'
import {  createNewsReader } from '@/services/news-reader/news-reader.service'
import { getContentRecentAll } from '@/services/quiz-creator/quiz-creator.service'
import {  getAudioDrafts } from '@/services/drafts/drafts.service'
import { getMasterLanguages } from '@/services/master-languages/master_languages.service';
import { checkDiscount } from '@/services/referral/referral.service';

import VueLottie from 'vue-lottie';
import loadAutoDub from '@/assets/animations/load_autodub2.json';

export default {
	mixins: [AlertUtils, exportUtils],
	components :{
		// BannerHome,
		// TemplatesHome,
		ADUploadCard,
		AiVoicesGeneratorCard,
		AVSelectCharacter,
		CardList,
		ModalUploadAutoDubb,
		ServerErrorModal,
		Soca,
		ADLangSameDetected,
		QuotaHabisModal,
		ADNoAudioDetected,
		VueLottie,
		ADPlayPreviewModal,
		AVQuotaHabis,
		// ADInfoAcessModal,
		ADVideoDurationExceeds,
		VCCharacterPackageModal,
		InsuficientQuotaADModal,
		VoiceServiceUnavailableModal,
		ModalPackageDiscount
	},
	data() {
		return {
			jobId: null,
			showButtonScroll: true,
			scrollPosition: 0,
			previousValueScroll: null,

			dataCampaign: {},
			load_topup: false,
			load_campaign: false,

			animationOptions: {
				animationData: loadAutoDub,
				loop: true,
				autoplay: true,
				scale: 0.1,
			},
			videoTutorial: [],
			isGetVideo: false,
			hideScrollButton: false,
			url: null,
			totalQueueAutodub: 0,
			languages: [],
			openLang: false,
			avatar: {
				// character: {
				// 	gender: 'Female',
				// 	name: 'Sovia',
				// 	avatarSrc: base_url_machine_learning + 'static/template/mp3/asset_sample_languages/Indonesia/Female/avatar/Sovia.png',
				// 	isMultilingual:false,
				// 	isUseModel: false,
				// 	sampleSrc: base_url_machine_learning + 'static/template/mp3/asset_sample_languages/Indonesia/Female/normal/1677909003.mp3',
				// 	thumbnailAvatarSrc: base_url_machine_learning + 'static/template/mp3/asset_sample_languages/Indonesia/Female/avatar/thumbnails/Sovia.png',
				// },
				// language: {
				// 	code: 'ID',
				// 	flagSrc: '/img/ic_flag_indonesia.bfd56f3d.svg',
				// 	id: '6401ae940eb4d9111ec260d7',
				// 	name: 'Indonesia',
				// }
				// SHERIN
				character: {
					avatarSrc: base_url_machine_learning + "static/template/mp3/asset_sample_languages/Multilingual/Martin/avatar/avatar.png",
                    button_active: true,
                    gender: "Male",
                    isMultilingual: true,
                    isUseModel: false,
                    name: "Martin",
                    package_id: ["64fe7082cbc3fea3d0fa93aa"],
                    sampleSrc: base_url_machine_learning+"static/template/mp3/asset_sample_languages/Multilingual/Martin/sample.mp3",
                    thumbnailAvatarSrc: base_url_machine_learning+"static/template/mp3/asset_sample_languages/Multilingual/Martin/avatar/thumbnails/avatar.png"
				},
				language: {
					code: "MULTILINGUAL",
                    flagSrc: base_url_machine_learning+"static/template/mp3/asset_sample_languages/Multilingual/flags.png",
                    id: "64edd8a1c8db426179185b87",
                    name: "Multilingual"
				}
			},
			listVideoPreview: [

				{
					thumbnail: require('@/assets/images/JackieChan.jpeg'),
					url: base_url_asset + 'auto-dubb-example/video/jackie-chan-video-example.m4v'
				},
				{
					thumbnail: require('@/assets/images/SusterNgesot.png'),
					url: base_url_asset + 'auto-dubb-example/video/suster-ngesot-video-example.m4v'
				},
				{
					thumbnail: require('@/assets/images/AADC.png'),
					url: base_url_asset + 'auto-dubb-example/video/aadc-video-example.m4v',
				},
			],
			valueCardVideo: {
				fileVideo: {
					file: null,
					src: null,
					name: null,
					type: null,
					thumbnail: null,
					fileSize: null,
				},
				additionalInclude: false,
				speaker: 1,
				targetLang: {
					id: '6401ae940eb4d9111ec260d7',
					name: 'Indonesia',
					code: 'ID',
					flagSrc: require('@/assets/images/icons/ic_flag_indonesia.svg'),
				},
				fileSrt1: {
					file: null,
					fileName: null,
					lang: null
				},
				fileSrt2: {
					file: null,
					fileName: null,
					lang: null
				},
			},
			previewUrl: null,
			isSubmit: false,
			typeFileUploaded: 'video',
			allocation: {},
			isGenerate: false,
			isGenerateVoice: false,
			courseId: null,
			isSaving: false,
			intervalFinal: null,
			intervalFinal2: null,
			loadingLanguages: false,
			progressGetScript: 0,
			scripts: [],
			finalPath: null,
			generatedVoiceDataPath: null,
			isPlayingAV: false,
			generatedVoiceData: {
				path: ""
			},
				formVoice: {
					text: '"Are you sure about that?" he said, confused.\n"Don’t test me Joker!" he shouted angrily. Bruce mulai marah kepada Joker, karena telah merebut permen kesukaannya.'
				},

			active_play: false,
			status_dubb: false,
			recent_load: false,
			draft_load: false,
			contentRecent: [],
			draftsAudio: [],
			load_alloc: false,
			status_button: false,
			previousVoice: '"Are you sure about that?" he said, confused.\n"Don’t test me Joker!" he shouted angrily. Bruce mulai marah kepada Joker, karena telah merebut permen kesukaannya.',
			status_discount_user: false,
			list_data_discount: []
		}
	},
	watch: {
		status_discount_user(val){
			if(val){
				this.$bvModal.show('modal--package-discount');
			}
		},
		"formVoice.text"(val){
			if(this.active_play){
				this.active_play = false;
			}

			if(this.generatedVoiceDataPath){
				if(val === this.previousVoice){
					this.active_play = true;
				}else{
					this.active_play = false;
				}
			}

		},

		avatar(){
			this.active_play = false;
		},
		generatedVoiceDataPath(val){
			console.log(val);
		},

		valueCardVideo: {
			handler(newValue) {
				if(newValue.fileVideo.name) {
					sessionStorage.setItem('auto-dub::video-name', newValue.fileVideo.name);
				}

				if(newValue.fileVideo.fileSize) {
					sessionStorage.setItem('auto-dub::file-size', newValue.fileVideo.fileSize);
				}

				if(newValue.fileVideo.thumbnail) {
					sessionStorage.setItem('auto-dub::thumbnail', newValue.fileVideo.thumbnail);
				}
			},
			deep: true,
		},
	},
	async mounted() {
		this.checkDiscountUserReferral();

		
        $("div.home").parent().addClass('pb-0');
		await this.handleGetDataFromStorage()
		await this.getStatusButtonActiveSherin();
		this.getAllocation()
		this.getVideo()
		this.getQueueAutodub()
		this.populateLanguages()
		await this.getContentRecent();
		await this.getAudioDrafts();

		if(this.jobId && this.scripts.length == 0) {
			this.getJobStatusIfReload()
		}
		const audio = this.$refs.AVResult;

		audio.addEventListener('ended', () => {
			this.isPlayingAV = false;
		});
	},
	created(){
		window.addEventListener('scroll', this.handleShowButton)
	},
	destroyed(){
		window.addEventListener('scroll', this.handleShowButton)
	},
	beforeDestroy() {
		$("div.home").parent().addClass('pb-0');
		if(this.intervalFinal) {
			clearInterval(this.intervalFinal)
		}
	},
	methods: {
		checkDiscountUserReferral(){
			checkDiscount().then((res)=>{
				this.status_discount_user = res.status;
				if(res.status){
					this.list_data_discount = res.data;
				}
			}).catch((err)=>{
				console.log(err)
			})
		},
		async getStatusButtonActiveSherin(){
			try {
          const { data } = await getMasterLanguages({
            filter: 'Multilingual',
            page: 1,
            limit: 20
          });
			const sherin = data[0].Detail.filter(item => item.Name === 'Martin');
			this.avatar.character.package_id = sherin[0]?.package_id ? sherin[0].package_id : []
			this.status_button = sherin[0]?.button_active ? true : false
        } catch (error) {
          console.error(error);
        }
		},

		async getContentRecent(){
            this.recent_load = true;
            await getContentRecentAll().then((response)=>{
                if(response.status){
                    this.contentRecent = response.data;
					console.log(this.contentRecent);
                    this.recent_load = false;
                } else {
                    this.contentRecent = [];
                    this.recent_load = false;
                }
            }).catch((err)=>{
                this.recent_load = false;
                console.log("data err", err)
            })
        },
		async getAudioDrafts(){
			this.draft_load = true;
            await getAudioDrafts()
            .then((response) =>{
                if(response.status){
                    this.draftsAudio = response.data
					this.draft_load = false;
                } else {
                    this.draftsAudio = []
					this.draft_load = false;
                }
            }).catch((err)=>{
				this.draft_load = false;
                console.log("data err", err)
            })
        },
		toNewsReader() {
            createNewsReader().then((response) => {
                if(response.status) {
                    this.$router.push({ name : 'NewsReader', params : { mode : 'create', id : response.data.content_id } })
                }
            })
        },
		checkAccessAD(toPage) {
            autodubbCheckAccess().then((response) => {
                if(!response.status) {
					this.$bvModal.show('info-access-autodubb');
                }else{
					if(toPage){
						this.$router.push({ name : 'AutoDubbing', params : { mode : 'new', id : '1' } })
					}else{
						this.$bvModal.show('upload-autodubb');
					}
				}
				this.status_dubb = response.status;
            }).catch(() => {
            })
        },
		playVoiceResult() {
            const audio = this.$refs.AVResult;

            if (this.isPlayingAV) {
                audio.pause();
            } else {
                audio.play();
            }

            this.isPlayingAV = !this.isPlayingAV;
        },
		handleDownloadAudio() {
			axios.get(this.generatedVoiceDataPath, { responseType: 'blob' })
			.then((res) => {
				this.exportAudio(res.data, this.generatedVoiceDataPath ? this.generatedVoiceDataPath.substring(this.generatedVoiceDataPath.lastIndexOf('/')+1).replace('.mp3', '') : 'Audio - SOCA AI')
				this.alertSuccess(this.$t('content-successfully'))
			})
			.catch((err) => {
				console.log(err)
				this.alertFail('Something went wrong !')
			})
		},
		async handleGenerateAiVoice() {
			// if(!this.status_button && this.avatar.character?.isMultilingual){
			// 	console.log(this.status_button)
			// 	console.log(this.avatar)
			// 	console.log("ASUP KADIE")
			// 	this.$bvModal.show(`package-character-${this.avatar.character.name}`);
			// 	return;
			// }

			this.isGenerateVoice = true;
			try {
				this.formVoice = {
					context: "lessons",
					course_id: "",
					params: {
						emotion: "normal",
						lang: this.avatar.language.id,
						pitch: 0,
						regenerate: "True",
						speaker: this.avatar.character.name.toLowerCase(),
						speed: 1,
						ssml: "true",
						with_char: "False"
					},
					text: this.formVoice.text
				}
				if (this.avatar.character.isUseModel && !this.avatar.character?.isMultilingual) {
					var form = {
						course_id: null,
						id_models: this.avatar.character.id,
						lang: this.avatar.language.id,
						params: {
						with_char: "False"
						},
						text: this.formVoice.text
					}
					this.previousVoice = this.formVoice.text;
					const res = await useVoiceModel(form)

					if (res.status === 403) {
						this.$bvModal.show('audio-quota');
						this.isGenerateVoice = false;
					} else if (res.status === 402 || res.status === 500) {
						this.$bvModal.show('server-error-modal');
						this.isGenerateVoice = false;
					} else if (res.status === 406) {
						this.$bvModal.show('sentiment-analysis-warning');
						this.isGenerateVoice = false;
					} else {
						const { data } = res;
						this.generatedVoiceDataPath = data.path
						this.isGenerateVoice = false;
						this.active_play = true;
						setTimeout(() => {
						if (data.path != '') {
							EventBus.$emit('triggerSidebarMethod');
							this.getAllocation()
						}
						},1000)
					}
			} else {
				this.previousVoice = this.formVoice.text;
				const res = await getGeneratedTextToAudioSSML(this.formVoice);

				if (res.status === 403) {
					this.$bvModal.show('audio-quota');
					this.isGenerateVoice = false;
				} else if (res.status === 402) {
						this.$bvModal.show('voice-service-unavailable-home')
				} else if (res.status === 500 || res.status === undefined || res.status === 'undefined') {
					this.$bvModal.show('server-error-modal');
					this.isGenerateVoice = false;
				} else if (res.status === 406) {
					this.$bvModal.show('sentiment-analysis-warning');
					this.isGenerateVoice = false;
				} else {
					const { data } = res;
					this.generatedVoiceDataPath = base_url_machine_learning + data.path
					this.isGenerateVoice = false;
					this.active_play = true;

					setTimeout(() => {
					if (data.path != '') {
						EventBus.$emit('triggerSidebarMethod');
						this.getAllocation()
					}
					},1000)
          		}
				this.isGenerateVoice = false;
			}

      } catch (error) {
        console.log(error);
		this.isGenerateVoice = false;
        this.$bvModal.show('server-error-modal');
      }

      return Promise.resolve();
    },
		handleOpenLang() {
			this.$bvModal.hide('target-lang-same-modal')
			this.openLang = true
		},
		getJobStatusIfReload() {
			this.isSubmit = true
			this.intervalFinal = setInterval(() => {
				getJobStatus(this.jobId)
				.then((res) => {
					if(res.status == 200) {
						this.progressGetScript = parseInt(res.data.progress)
						if(res.data.message == 'Target Lang Same with Detected Lang') {
							this.$bvModal.show('target-lang-same-modal')
							clearInterval(this.intervalFinal)
							this.resetValue()
							this.removeDataFromStorage()
							this.isSubmit = false
							this.progressGetScript = 0
							this.openLang = false
						}
						if(res.data.status === "finish_script") {
							sessionStorage.removeItem('auto-dub::scripts')
							this.courseId = res.data.course_id
							let script = base_url_auto_dubbing + res.data.script_path.replace('/src', '')
							this.getScriptFromJson(script)
							clearInterval(this.intervalFinal)
						}
					} else if(res.status === 402 || res.status === 500) {
						this.$bvModal.show('server-error-modal');
						this.isSubmit = false
					}
				})
				.catch((error) => {
					this.isSubmit = false
					console.error(error)
					this.$bvModal.show('server-error-modal');
				})
			}, 5000)
		},
		async populateLanguages() {
			this.loadingLanguages = true;

			try {
				const { data: languages } = await getMasterLanguagesAutodub();

				this.languages = [];

				for (const language of languages) {
					this.languages.push({
						name: language.DisplayName,
						code: language.LanguageCode.toUpperCase(),
						flagSrc: base_url_machine_learning + language.Flags,
						id: language._id.$oid
					});
				}
			} catch (error) {
				console.error(error);
			}

			this.loadingLanguages = false;
		},

		getScriptFromJson(jsonURL) {
			this.scripts = []
			axios.get(jsonURL)
			.then(response => {
				let data = response.data.data
				let facePath = response.data.face_path
				data.forEach((item) => {
					this.scripts.push({
						id: item.id,
						lang: item.lang,
						text: item.text,
						translate: item.translate,
						start: item.start,
						end: item.end,
						speaker: item.speaker,
						img: base_url_auto_dubbing + facePath[item['speaker'].split('_')[1]-1].replace('/src', ''),
						isEdit: false,
						lang_source: 'detect',
						lang_target: this.valueCardVideo.targetLang.id
					})
				})
				sessionStorage.setItem("auto-dub::scripts", JSON.stringify(this.scripts))
				sessionStorage.setItem("auto-dub::job-id", this.jobId)
				sessionStorage.setItem("auto-dub::course-id", this.courseId)

				this.$router.push({name: 'AutoDubbing', params: { mode: 'new', id: '1' }});

				this.isSubmit = false
			})
			.catch(error => {
				console.error('Gagal mengambil data JSON:', error);
			});
		},
		async handleGetDataFromStorage() {
			if(sessionStorage.getItem('auto-dub::uploaded-card-value')) {
				this.valueCardVideo = await JSON.parse(sessionStorage.getItem('auto-dub::uploaded-card-value'))
			}

			if(sessionStorage.getItem('auto-dub::scripts')) {
				this.scripts = await JSON.parse(sessionStorage.getItem("auto-dub::scripts"))
			}

			if(sessionStorage.getItem('auto-dub::job-id')) {
				this.jobId = sessionStorage.getItem("auto-dub::job-id")
			}

			if(sessionStorage.getItem('auto-dub::course-id')) {
				this.courseId = sessionStorage.getItem("auto-dub::course-id")
			}

			if(sessionStorage.getItem('auto-dub::video-name')) {
				this.valueCardVideo.fileVideo.name = sessionStorage.getItem('auto-dub::video-name')
			}

			if(sessionStorage.getItem('auto-dub::file-size')) {
				this.valueCardVideo.fileVideo.fileSize = sessionStorage.getItem('auto-dub::file-size')
			}

			if(sessionStorage.getItem('auto-dub::thumbnail')) {
				this.valueCardVideo.fileVideo.thumbnail = sessionStorage.getItem('auto-dub::thumbnail')
			}
		},
		handleGenerateScript() {
			this.isGenerate = true
			const jsonData = this.scripts;
			const jsonContent = JSON.stringify(jsonData);

			const blob = new Blob([jsonContent], { type: 'application/json' });
			const formData = new FormData();

			formData.append('script', blob, 'data.json');
			formData.append('job_id', this.jobId);
			formData.append('course_id', this.courseId);
			generateScriptAutodub(formData)
			.then((response) => {
				if(response.status == 200) {
					this.postToProjects()
				} else {
					this.$bvModal.show('server-error-modal');
				}
				this.isGenerate = false
			})
			.catch((error) => {
				this.$bvModal.show('server-error-modal');
				this.isGenerate = false
				console.error(error)
			})
		},
		getQueueAutodub() {
            getQueueAutodub()
            .then((response) => {
                if(response.status == 200) {
                    this.totalQueueAutodub = response.data
                }
            })
        },
		async handleGetScript() {
			this.isSubmit = true

			let index = 1
			let course_id = null
			this.$bvModal.hide('upload-autodubb')
			if(this.valueCardVideo.fileVideo.type == 'video') {
				if(this.valueCardVideo.fileVideo.file && this.valueCardVideo.fileVideo.file.length > 0) {
					for(const chunk of this.valueCardVideo.fileVideo.file) {

						let chunksData = new FormData();

						chunksData.append('course_id', course_id || '');
						chunksData.append('file_name', this.valueCardVideo.fileVideo.name);
						chunksData.append('chunk_number', index.toString());
						chunksData.append('total_chunks', this.valueCardVideo.fileVideo.file.length || 0);
						chunksData.append('file_size', chunk.size);
						chunksData.append('file_chunk', chunk);

						await uploadFileByChunks(chunksData)
						.then((response) => {
							if(response.status == 200){
								if(!course_id) {
									course_id = response.data.course_id
								}
								if(response.data.path) {
									let formData = new FormData();
									formData.append('path_video', response.data.path);
									formData.append('generate', this.valueCardVideo.additionalInclude);
									formData.append('course_id', response.data.course_id);
									formData.append('num_speaker', this.valueCardVideo.speaker);
									formData.append('target_lang', this.valueCardVideo.targetLang.id);

									getScriptAutodub(formData)
									.then((response) => {
										if(response.status == 200) {
											let jobId = response.data.job_id
											this.valueCardVideo.fileVideo.thumbnail = response.data.all_slide.path_thumbnail ? base_url_auto_dubbing + response.data.all_slide.path_thumbnail.replace('src', '') : null
											if(this.valueCardVideo.additionalInclude) {
												this.jobId = jobId
												this.postToProjects()
											} else {
												if(jobId) {
													this.jobId = jobId
													this.intervalFinal = setInterval(() => {
														getJobStatus(jobId)
														.then((res) => {
															if(res.status == 200) {
																this.getQueueAutodub()
																this.progressGetScript = parseInt(res.data.progress)
																if(res.data.message == 'Target Lang Same with Detected Lang') {
																	this.$bvModal.show('target-lang-same-modal')
																	clearInterval(this.intervalFinal)
																	this.resetValue()
																	this.removeDataFromStorage()
																	this.isSubmit = false
																	this.progressGetScript = 0
																	this.openLang = false
																}
																if(res.data.status === "finish_script") {
																	sessionStorage.removeItem('auto-dub::scripts')
																	this.jobId = jobId
																	this.courseId = res.data.course_id
																	let script = base_url_auto_dubbing + res.data.script_path.replace('/src', '')
																	this.getScriptFromJson(script)
																	this.getAllocation()
																	clearInterval(this.intervalFinal)
																}
															} else if(res.status === 402 || res.status === 500) {
																this.$bvModal.show('server-error-modal');
																this.isSubmit = false
															}
														})
														.catch((error) => {
															this.isSubmit = false
															console.error(error)
															this.$bvModal.show('server-error-modal');
														})
													}, 5000)
												}
											}
										} else if(response.status == 403) {
											this.isSubmit = false
											this.$bvModal.show('insuficient-qad');
										} else if(response.status === 402 || response.status === 500) {
											this.isSubmit = false
											this.$bvModal.show('server-error-modal');
										} else if (response.status == 405) {
											this.isSubmit = false
											this.alertFail(response.data)
										} else if (response.response.status == 405) {
											this.isSubmit = false
											this.alertFail(response.response.data.message)
										} else if (response.response.status == 403) {
											this.isSubmit = false
											this.$bvModal.show('insuficient-qad');
										} else if(response.response.status == 500) {
											this.isSubmit = false
											this.$bvModal.show('server-error-modal');
										} else {
											this.isSubmit = false
										}
									})
									.catch((error) => {
										this.isSubmit = false
										this.$bvModal.show('server-error-modal');
										console.error(error)
									})
								}
							} else if (response.status == 403) {
								this.isSubmit = false
								this.$bvModal.show('insuficient-qad');
							} else if(response.response.status == 405 && response.response.data.message == "Audio not found") {
								this.$bvModal.show('no-audio-detected-modal')
								this.isSubmit = false
								this.resetValue()
							} else if (response.response.status == 403) {
								this.isSubmit = false
								this.$bvModal.show('insuficient-qad');
							} else if (response.response.status == 405) {
								this.isSubmit = false
								this.alertFail(response.response.data.message)
							} else {
								this.isSubmit = false
							}
						})
						index++
					}
				} else {
					this.alertFail("Seems you haven't uploaded the file yet")
				}
			} else {
				let formData = new FormData();
				formData.append('file', this.valueCardVideo.fileVideo.file);
				formData.append('generate', this.valueCardVideo.additionalInclude);
				formData.append('course_id', null);
				formData.append('num_speaker', this.valueCardVideo.speaker);
				formData.append('target_lang', this.valueCardVideo.targetLang.id);

				getScriptAutodub(formData)
				.then((response) => {
					if(response.status == 200) {
						let jobId = response.data.job_id
						this.valueCardVideo.fileVideo.thumbnail = response.data.all_slide.path_thumbnail ? base_url_auto_dubbing + response.data.all_slide.path_thumbnail.replace('src', '') : null
						if(this.valueCardVideo.additionalInclude) {
							this.jobId = jobId
							this.postToProjects()
						} else {
							if(jobId) {
								this.jobId = jobId
								this.intervalFinal = setInterval(() => {
									getJobStatus(jobId)
									.then((res) => {
										if(res.status == 200) {
											this.getQueueAutodub()
											this.progressGetScript = parseInt(res.data.progress)
											if(res.data.message == 'Target Lang Same with Detected Lang') {
												this.$bvModal.show('target-lang-same-modal')
												clearInterval(this.intervalFinal)
												this.resetValue()
												this.removeDataFromStorage()
												this.isSubmit = false
												this.progressGetScript = 0
												this.openLang = false
											}
											if(res.data.status === "finish_script") {
												sessionStorage.removeItem('auto-dub::scripts')
												this.jobId = jobId
												this.courseId = res.data.course_id
												let script = base_url_auto_dubbing + res.data.script_path.replace('/src', '')
												this.getScriptFromJson(script)
												this.getAllocation()
												clearInterval(this.intervalFinal)
											}
										} else if(res.status === 402 || res.status === 500) {
											this.$bvModal.show('server-error-modal');
											this.isSubmit = false
										}
									})
									.catch((error) => {
										this.isSubmit = false
										console.error(error)
										this.$bvModal.show('server-error-modal');
									})
								}, 5000)
							}
						}
					} else if (response.status == 403) {
						this.isSubmit = false
						this.$bvModal.show('insuficient-qad');
					} else if(response.status === 402 || response.status === 500) {
						this.isSubmit = false
						this.$bvModal.show('server-error-modal');
					} else if (response.status == 405) {
						this.isSubmit = false
						this.alertFail(response.data)
					} else if (response.response.status == 405) {
						this.isSubmit = false
						this.alertFail(response.response.data.message)
					} else if (response.response.status == 403) {
						this.isSubmit = false
						this.$bvModal.show('insuficient-qad');
					} else if(response.response.status == 500) {
						this.isSubmit = false
						this.$bvModal.show('server-error-modal');
					} else {
						this.isSubmit = false
					}
				})
				.catch((error) => {
					this.isSubmit = false
					this.$bvModal.show('server-error-modal');
					console.error(error)
				})
			}
		},
		postToProjects() {
			this.isSaving = true;
			let formData = {
				job_id: this.jobId || null,
				course_id: this.courseId || null,
				image_thumbnail: null, // Default: null
				content_name: this.valueCardVideo.fileVideo.name || 'Auto Dubbing',
				content_file_size: parseInt(this.valueCardVideo.fileVideo.fileSize) || 0,
				content_file: null,
			};

			if (this.valueCardVideo.fileVideo.thumbnail) {
				fetch(this.valueCardVideo.fileVideo.thumbnail)
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader();
					reader.onload = () => {
					formData.image_thumbnail = reader.result; // Setelah gambar dalam format base64 tersedia
					this.isSaving = true;

					postAutodub(formData)
						.then((response) => {
							if (response.status) {
								this.removeDataFromStorage();
								this.$router.push({ name: 'My-Quiz', query: { folder_name: 'Dubbs' } });
							} else if (response.response.status == 403) {
								this.isSaving = false
								this.$bvModal.show('insuficient-qad');
							}
							this.isSaving = false;
						})
						.catch((error) => {
							this.isSaving = false;
							console.error(error);
						});
					};
					reader.readAsDataURL(blob);
				})
				.catch((error) => {
					this.isSaving = false;
					console.error(error);
				});
			} else {
				postAutodub(formData)
				.then((response) => {
					if (response.status) {
						this.removeDataFromStorage()
						this.$router.push({ name: 'My-Quiz', query: { folder_name: 'Dubbs' } });
					} else {
						console.log(response);
					}
					this.isSaving = false;
				})
				.catch((error) => {
					this.isSaving = false;
					console.error(error);
				});
			}
		},
		removeDataFromStorage() {
			sessionStorage.removeItem('auto-dub::uploaded-card-value')
			sessionStorage.removeItem('auto-dub::scripts')
			sessionStorage.removeItem('auto-dub::job-id')
			sessionStorage.removeItem('auto-dub::course-id')
			sessionStorage.removeItem('auto-dub::video-name')
			sessionStorage.removeItem('auto-dub::file-size')
			sessionStorage.removeItem('auto-dub::thumbnail')
		},
		resetValue(){
			this.valueCardVideo =  {
				fileVideo: {
					file: null,
					src: null,
					name: null,
					type: null,
					thumbnail: null,
					fileSize: null,
				},
				additionalInclude: false,
				speaker: 1,
				targetLang: {
					id: '6401ae940eb4d9111ec260d7',
					name: 'Indonesia',
					code: 'ID',
					flagSrc: require('@/assets/images/icons/ic_flag_indonesia.svg'),
				},
				fileSrt1: {
					file: null,
					fileName: null,
					lang: null
				},
				fileSrt2: {
					file: null,
					fileName: null,
					lang: null
				}
			}
			this.openLang = false
		},
		getMonthDate(dateTime) {
			const months = [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			];

			const dateObject = new Date(dateTime);
			const tanggal = dateObject.getUTCDate();
			const monthIndex = dateObject.getUTCMonth();
			const month = months[monthIndex];

			return `${month} ${tanggal}`;
		},
		handleClickPlayPreview(url) {
			this.previewUrl = url

			if(this.previewUrl) {
				this.$bvModal.show('ad-play-preview')
			}
		},
		handleShowButton(){
			let windowHeight = window.innerHeight;

			let scrollY = window.scrollY;

			let documentHeight = document.documentElement.scrollHeight;

			// console.log("windowHeight : "+ windowHeight)
			// console.log("scrollY : "+scrollY)
			// console.log("documentHeight : "+documentHeight)

			if (scrollY + windowHeight >= documentHeight) {
				this.hideScrollButton = true;
			}else{
				this.hideScrollButton = false;
			}
		},
		handleScroll(){

			let windowHeight = window.innerHeight;

			let scrollY = window.scrollY;

			let documentHeight = document.documentElement.scrollHeight;

			if (scrollY + windowHeight >= documentHeight) {
				this.hideScrollButton = true;
			}

			window.scrollBy(0, 250);

		},
		async getAllocation() {
			this.load_alloc = true;
			await subscriptionsStatus()
			.then((res) => {
				if(res.status) {
					this.allocation = res.data
				}
				this.load_alloc = false;
			})
		},

		getDataCampaign() {
			this.load_campaign = true;

			getPackageCampaignNew()
			.then((response) => {
				if(response.status) {
					this.load_campaign = false;
					this.dataCampaign = response.data
				} else {
					this.dataCampaign = null
					this.load_campaign = false;
				}
			})
			.catch((error) => {
				this.dataCampaign = null
				this.load_campaign = false;
				console.error(error)
			})
		},
		createInvoice(id, community_id){
			this.load_topup = true;

			let formData = {
				package_id : id,
				community_id : community_id ? community_id : null
			}

			topupSwc(formData)
			.then((response) => {
				if(response.status == true){
					this.load_topup = false;
					this.$router.push({ name : 'Subscriptions', query : { id : response.data.subscription_id } })
				}
			})
			.catch((err) => {
				this.load_topup = false;
				console.log(err)
			})
		},
		async getVideo() {
			this.isGetVideo = true
			await getMasterVideoTutorial()
			.then((response) => {
				if(response.status) {
					this.videoTutorial = response.data
					this.isGetVideo = false
				} else {
					this.isGetVideo = false
				}
			})
			.catch((error) => {
				console.error(error)
				this.isGetVideo = false
			})
		},

		handleClickPlayVideo(url) {
			this.url = url
			if(this.url) {
				this.$bvModal.show('show-video')
			}
		},

		removeDataADFromStorage() {
			sessionStorage.removeItem('auto-dub::uploaded-card-value')
			sessionStorage.removeItem('auto-dub::scripts')
			sessionStorage.removeItem('auto-dub::job-id')
			sessionStorage.removeItem('auto-dub::course-id')
			sessionStorage.removeItem('auto-dub::video-name')
			sessionStorage.removeItem('auto-dub::file-size')
			sessionStorage.removeItem('auto-dub::thumbnail')
		},
	}
}
</script>
<style scoped>
.auto-dub__submit--text {
	color: #FFF;
	font-size: 16px;
	font-weight: 400;
}
.auto-dub__submit{
	background-color: rgba(0, 0, 0, 0.5);
  	backdrop-filter: blur(5px);
	position: fixed;
  	top: 0;
}
.auto-dub__progress {
	border-radius: 4px;
	height: 7.3px;
	background-color: #3E4350;
	width: 500px;
}

.auto-dub__progress .progress-bar {
	background-color: #6D75F6;
	border-radius: 4px;
}

.adp__dropdown:hover .adp__tooltip {
	display: block;
}
.auto-dubbing__subscription-text {
  font-size: 13px;
  font-weight: 500;
}
.adp__tooltip {
	left: -222px !important;
	width: auto !important;
	background-color: #1F1F1F;
	color: #ffffff;
	font-size: 12px;
	padding: 10px 15px;
}
.gv__tooltip--topup {
	color: #6D75F6;
}

.gv__tooltip--package {
	gap: 6px;
}

.gv__tooltip--package-wrapper {
	border-radius: 4px;
	background: #2D2D2D;
}

.gv__tooltip--package-wrapper-inner {
	gap: 4px;
}
.tooltip--package__text {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.tooltip--package__quota {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.gv__tooltip--package-quota {
	gap: 4px;
}

.tooltip--package__exp {
	color: #8C8C8C;
	font-family: Avenir Next;
	font-size: 8px;
	font-style: italic;
	font-weight: 500;
	line-height: normal;
}
.ad-uc__info--inner {
	left: 0;
	width: 350px;
	border-radius: 6px;
	background-color: #1F1F1F;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);

	visibility: hidden;
	opacity: 0;
	transition: opacity 0.25s;
}
.ad-uc__icon-info:hover .ad-uc__info--inner {
	visibility: visible;
	opacity: 1;
}
.content__middle{
	gap: 35px;
    min-height: 0;
}
.content__left{
	width: 450px;
    gap: 12px;
}
.content__right {
	width: 100%;
	gap: 12px;
}
.AD__title--home, .AV__title--home{
	color: #FFF;
	font-family: 'Avenir Next';
	font-size: 17px;
	font-style: normal;
	font-weight: 500;
	display: flex;
}
.pr--content{
	display: contents;
}
.home {
	color: #fff;
	text-align: left;
	/* gap: 24px; */
}

.home__inner {
	gap: 35px;
}

.home__title {
	font-size: 24px;
	font-weight: 900;
}

.home__select-filter li button{
	/* margin: 0 !important; */
    padding: 0.4rem 1.2rem;
    border: 2px solid #2D2D2D;
    background-color: transparent;
    color: #8C8C8C;
    border-radius: 46px;
    transition: border 0.2s;
	margin: 0px 10px 0px 0px !important;
}
.home__select-filter .nav-tabs {
    border: none;
	gap: 10px;
}

.home__select-filter li button:hover {
    border-color: #585859;
}

.home__select-filter li .active {
    background-color: #6D75F6;
    color: white;
    border: 2px solid #6D75F6;
}

.home__subtitle {
	font-size: 18px;
	font-weight: 400;
}

.home__select-filter .form-control{
	/* background-color: transparent;
	border: none;
	box-shadow: none;
	color: #8C8C8C; */
}


.btn-close{
        background-color: #EDEDED;
        border-radius: 50%;
        width: 27px;
        height: 27px;
    }

    .btn-impor-custom{
        border: 2px dashed #2D2D2D;
        height: 20rem;
        border-radius: 10px;
    }

    .btn-grey{
        background-color: #6D75F6 !important;
        color: white;
        border: 0px !important;
    }

    .btn-purple {
        background-color: #6D75F6;
        border: 1px solid #6D75F6;
        color: white;
    }

    .progress__upload .progress-bar {
        background-color: #6D75F6;
    }

    .text--small {
        font-size: 12px;
        color: #979797;
    }
</style>