<template>
    	<div class="pr--box d-flex align-items-center m-0">
			<div v-for="(card, index) in card_bottom" :key="index" :class="index == 1 && draftsAudio.length == 0 ? 'col-3 d-flex align-items-center default' : 'col-3 bh__q-gen--wrapper d-flex align-items-center cursor-pointer'" @click="to(card)">
				<div class="bh__q-gen px-3 py-3" :data-toggle="card.link_text == 'create-new' ? 'dropdown' : ''" aria-expanded="false">
					<div class="q-gen--action d-flex align-items-center justify-content-between mb-2">
						<h6 v-if="!card.logo" class="title--card">{{ $t(card.title) }}</h6>
						<img :src="card.logo" alt="" v-if="card.logo">
					</div>
					<div class="q-gen--desc d-flex flex-column">
						<p class="q-gen--title" :style="index == 2 ? 'margin-bottom: 2.4rem' : index ==  3 ? 'margin-bottom: 2.3rem' : ''" v-html="$t(card.text)" v-if="card.text"></p>

                        <template v-if="card.line1 && card.line2">
                            <template v-if="card.title == 'latest-projects'">
                                <p class="line--1 mb-0" v-if="contentRecent.length > 0" data-toggle="tooltip" data-placement="bottom" :title="contentRecent[0].content_name">{{ recent_load ? "Loading..." : contentRecent[0].content_name }}</p>
                                <p class="line--2" v-if="contentRecent.length > 0">{{ recent_load ? "Loading..." : getDate(contentRecent[0].updatedAt) }}</p>
                                <p class="line--no_have-content" v-if="contentRecent.length == 0">{{ recent_load ? "Loading..." : $t('no-ongoing-projects') }}</p>
                            </template>
                            <template v-else-if="card.title == 'your-draft'">
                                <p class="line--1 mb-0" v-if="draftsAudio.length > 0" data-toggle="tooltip" data-placement="bottom" :title="draftsAudio[0].content_name">{{ draft_load ? "Loading..." : draftsAudio[0].content_name }}</p>
                                <p class="line--2" v-if="draftsAudio.length > 0">{{ draft_load ? "Loading..." : getDate(draftsAudio[0].updatedAt) }}</p>
                                <p class="line--no_have-content" v-if="draftsAudio.length == 0">{{ draft_load ? "Loading..." : $t('no-drafts-available.') }}</p>
                            </template>
                        </template>

					</div>
                    <h6 :class="index == 1 && draftsAudio.length == 0 ? 'link__text hide' : 'link__text'">{{ $t(card.link_text) }} <i class="bi bi-arrow-right "></i></h6>

                    <div class="dropdown create-new mr-2" v-if="card.link_text == 'create-new'">
                        <div class="dropdown-menu dropdown-menu-right mt-2">
                            <div class="px-4 mt-2">
                                <span>{{ $t('start-with--p') }}</span>
                                <div class="items-create mt-2" @click="$router.push({ name : 'Generate-Videos', params : { mode : 'new', id : '1' } })">
                                    <p class="m-0 cursor-pointer">Voice Overs Video</p>
                                </div>
                            </div>
                            <hr style="background:#2D2D2D;">
                            <div class="px-4 mb-2">
                                <span>{{ $t('or-start') }}</span>
                                <div v-for="(data, index) in ai_generator" :key="index" class="items-create mt-2 cursor-pointer" @click="handleClickCN(data.to, data.params)">
                                    <p class="mb-0 cusor-pointer">{{ data.title }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</div>
</template>

<script>
import { base_url_qgen } from "@/config/base_url"
import moment from 'moment';

export default {
    props: {
		status_dubb: {
			type: Boolean,
			default: false
		},
		draft_load: {
			type: Boolean,
			default: false
		},
		recent_load: {
			type: Boolean,
			default: false
		},
		contentRecent: {
			type: Array,
			default: () => {
				return []
			}
		},
		draftsAudio: {
			type: Array,
			default: () => {
				return []
			}
		},
    },
    data(){
        return{
            card_bottom: [
                {
					title: "latest-projects",
					line1: "Review-Film-A",
					line2: 'updated 5 hours ago',
                    link_text: 'create-new'
				},
				{
                    title: "your-draft",
					line1: "Review-Film-A",
					line2: 'updated 10 mins ago',
                    link_text: 'continue-this-project',
                    is_draft: true
				},
				{
                    title: "tutorial",
					text: 'tutorial--p',
                    link_text: 'watch-video',
                    route_name: 'WatchVideo'
				},
				{
                    logo: require('@/assets/images/q-gen-logo_new.svg'),
					text: "q-gen--p",
                    link_text: 'try-for-free',
                    qgen: true
				},
            ],
            ai_generator : [
                {
                    icon : 'ic_bulat.svg',
                    title : 'AI Voice Clone',
                    to: 'Personal-Voice'
                },
                {
                    icon : 'ic_segienam.svg',
                    title : 'AI News Reader',
                    to : 'NewsReader',
                },
                {
                    icon : 'ic_segienam.svg',
                    title : 'AI Dubbing',
                    to : 'AutoDubbing',
                    params : true
                },
            ],
        }
    },
    methods: {
        getDate(item){
            const date = moment(item);
            let now = moment();
            now = now.add(420, 'minutes')
            const diff = now.diff(date, 'seconds');

            if (diff < 1) {
                return this.$t('a-few-secs-ago');
            } else if (diff < 60) {
                return this.$t('updated') + ' ' + diff + ' ' + this.$t('secs-ago');
            } else if (diff < 3600) {
                const minutes = Math.floor(diff / 60);
                return this.$t('updated') + ' ' + minutes + ' ' + this.$t('minutes-ago');
            } else if (diff < 86400) {
                const hours = Math.floor(diff / 3600);
                return this.$t('updated') + ' ' + hours + ' ' + this.$t('hours-ago');
            } else if (diff < 604800) {
                const days = Math.floor(diff / 86400);
                return this.$t('updated') + ' ' + days + ' ' + this.$t('days-ago');
            } else if (diff < 2592000) {
                const weeks = Math.floor(diff / 604800);
                return this.$t('updated') + ' ' + weeks + ' ' + this.$t('weeks-ago');
            } else if (diff < 31536000) {
                const months = Math.floor(diff / 2592000);
                return this.$t('updated') + ' ' + months + ' ' + this.$t('months-ago');
            } else {
                const years = Math.floor(diff / 31536000);
                return this.$t('updated') + ' ' + years + ' ' + this.$t('years-ago');
            }
            },
        handleClickCN(val, params) {
            if(val == 'AutoDubbing'){
                this.$emit('click:show-info-autodubb', true)
            }else{
                if(val) {
                    if(params) {
                        this.$router.push({ name : val, params : { mode : 'new', id : '1' } })
                    } else if(val == 'NewsReader') {
                        this.$emit('click:to-news-reader')
                    } else {
                        this.$router.push({ name : val })
                    }
                }
            }
        },

        to(val) {
			if(val.is_draft && this.draftsAudio.length > 0 ) {
				this.$router.push({ name : 'Generate-Videos', params: { mode : 'draft', id : this.draftsAudio[0]._id } })
			}else if(val.route_name){
                this.$router.push({ name : val.route_name })
            }else if(val.qgen){
                let token = null

                if(localStorage.getItem("users")) {
                    var users = JSON.parse(localStorage.getItem("users"))
                    if(users.token) {
                        token = users.token
                    } else {
                        token = localStorage.getItem("token_client") ? localStorage.getItem("token_client") : null
                    }
                } else if (localStorage.getItem("token_client")) {
                    token = localStorage.getItem("token_client")
                }

                if(token) {
                    window.open(base_url_qgen + 'login?token=' + token);
                } else {
                    window.open(base_url_qgen);
                }
            }
		},
    }
}
</script>

<style scoped>
    .title--card{
        font-size: 17px;
    }
    .link__text.hide{
        visibility: hidden !important;
    }
    .create-new .dropdown-menu.show {
        background: #1F1F1F;
        display: grid;
        width: 250px;
        text-align: left;
        box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
        -webkit-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
    }

    .create-new .dropdown-menu span {
        font-size: 12px;
        color: #585859;
    }

    .create-new .dropdown-item, .card-body .dropdown-item {
        color: white;
    }

    .create-new .dropdown-item:hover, .card-body .dropdown-item:hover {
        background-color: #8C8C8C;
    }

    .create-new .dropdown-menu p {
        font-size: 14px !important;
        font-weight: 500 !important;
        color: white;
    }
    .pr--box{
        max-width: 97%;
    }
    .line--1{
        color: #8C8C8C;
        font-family: 'Avenir Next';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        max-width: 215px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .line--no_have-content{
        color: #8C8C8C;
        font-family: 'Avenir Next';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 3.2rem;
    }
    .line--2{
        color: #8C8C8C;
        font-family: 'Avenir Next';
        font-size: 10px;
        font-style: italic;
        font-weight: 400;
        margin-bottom: 2rem;
    }
    .link__text:hover{
        color: #545EED;
    }
    .link__text{
        color: #6D75F6;
        font-family: 'Avenir Next';
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        cursor: pointer;
        transition: color 0.25s;
    }
    .bh__q-gen--wrapper:hover{
        transform: translateY(-3px);
        
    }
    .pr--box .default{
        margin-right: 0.6rem;
        cursor: default;
    }
    .bh__q-gen--wrapper{
        
        margin-right: 0.6rem;
        transition: all .25s ease-in-out;
    }
    .bh__q-gen {
        border-radius: 8px;
        background: #1F1F1F;
        /* gap: 26px; */
        flex: 1 1 auto;
    }
    .q-gen--action {
        gap: 6px;
    }
    .q-gen--desc {
        gap: 2px;
    }
    .q-gen--title {
        color: #FFF;
        font-size: 13px;
        font-style: normal;
        line-height: normal;
        margin: 0;
    }

    .q-gen--action {
        gap: 6px;
    }
</style>