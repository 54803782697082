import axios from "../../config/axios"
import ApiRoute from '../api.route';
import { base_url_bossman } from "../../config/base_url";

export async function getMasterVideoTutorial() {
    try {
        const res = await axios.get(base_url_bossman + ApiRoute.masterVideos + '/list?platform=SOCA');
        return res.data;
    } catch (error) {
        const data = { status: false, response: error };
        return data;
    }
}