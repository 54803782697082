<template>
	<div class="ad-uc">
		<div class="ad-uc__inner d-flex flex-column position-relative">
			<div class="cxad-uc__inner--padding">
				<label class="ad-uc__label py-3 m-0 d-flex flex-column align-items-center justify-content-center w-100 cxad-uc__inner--padding" @click="$emit('click:show-modal')">
					<svg xmlns="http://www.w3.org/2000/svg" width="31" height="23" viewBox="0 0 31 23" fill="none">
						<rect x="2" y="2" width="27" height="19.6356" rx="2.4545" stroke="#6D75F6" stroke-width="2.05711"/>
						<path d="M2 4.4545C2 3.09892 3.09892 2 4.4545 2H26.5455C27.9011 2 29 3.09892 29 4.4545V6.90891H2V4.4545Z" stroke="#6D75F6" stroke-width="2.05711"/>
						<path d="M17.9582 13.4242C18.2855 13.6132 18.2855 14.0855 17.9582 14.2745L13.9082 16.6126C13.581 16.8016 13.1719 16.5654 13.1719 16.1875L13.1719 11.5112C13.1719 11.1333 13.581 10.8971 13.9082 11.086L17.9582 13.4242Z" fill="#6D75F6"/>
						<path d="M5.67969 6.90891L10.5888 2M13.0433 6.90891L17.9524 2M20.407 6.90891L25.316 2" stroke="#6D75F6" stroke-width="2.05711"/>
					</svg>
					<div class="d-flex flex-column">
						<p class="ad-uc__label-text1 m-0">{{ $t('upload-video') }}</p>
					</div>
				</label>
			</div>


			<p class="ad-uc__label-text3 text-white m-0 cxad-uc__inner--padding">{{ $t('example-results') }} </p>
			<div class="position-relative w-100">
				<div ref="listExample" class="ad-uc__list-example w-100 d-flex">
					<div v-for="(data, index) in listPreview" :key="index" class="ad-uc__card-video position-relative cursor-pointer" @click="$emit('click:play-preview', data.url)">
						<img :src="data.thumbnail" alt="" class="ad-uc__card-img">
						<div class="t__action-play">
							<button
								type="button"
								class="t__toggle btn p-0 rounded-0"
								@click="$emit('click:play-preview', data.url)"
							>
								<font-awesome-icon
									icon="fa-solid fa-play-circle"
									class="t__toggle-icon"
								/>
							</button>
						</div>
					</div>
				</div>
				<div ref="dividerLeft" class="divider-left"></div>
				<div ref="dividerRight" class="divider-right"></div>
			</div>

		</div>
	</div>
</template>

<script>
import { AlertUtils } from "@/mixins/AlertUtils";
// import ffmpeg from 'ffmpeg.js/ffmpeg-mp4.js';

export default {
	mixins: [AlertUtils],
	props: {
		languages: {
			type: Array,
			default: () => {
				return []
			}
		},
		selectedLanguage: {
			type: Object,
			default: () => ({
				id: '6401ae940eb4d9111ec260d7',
				name: 'Indonesia',
				code: 'ID',
				flagSrc: require('@/assets/images/icons/ic_flag_indonesia.svg'),
			}),
		},
		value: {
			type: Object,
			default: () => {
				return {}
			}
		},
		listPreview: {
			type: Array,
			default: () => {
				return []
			}
		},
		isSubmit: {
			type: Boolean,
			default: false
		},
		openLang: {
			type: Boolean,
			default: false
		},
		queue: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			speakers: [
				// {
				// 	id: 'auto_detect',
				// 	label: '✨Auto detect'
				// },
				{
					id: 1,
					label: '1 speaker'
				},
				{
					id: 2,
					label: '2 speaker'
				},
				{
					id: 3,
					label: '3 speaker'
				},
			],

			internalSelectedLanguage: this.selectedLanguage,
			formData: this.value,

			typeUpload: 'video',
		}
	},
	watch: {
		value: {
			handler(newValue) {
				this.formData = newValue
			},
			deep: true
		},
		formData: {
			handler(newValue) {
				this.$emit('input', newValue)
			},
			deep: true
		},
		openLang: {
			handler(newValue) {
				if(newValue) {
					this.toggleDropdownLang()
				}
			},
			deep: true
		}
	},
	computed: {
		sortedCountries() {
			if (this.languages.length > 0) {
				const sortedLanguages = [...this.languages];

				sortedLanguages.sort((a, b) => {
				const nameA = a.name.toLowerCase();
				const nameB = b.name.toLowerCase();

				if (nameA === 'multilingual') {
					return -1; // Pindahkan 'Multilingual' ke atas
				}

				if (nameB === 'multilingual') {
					return 1; // Pindahkan 'Multilingual' ke atas
				}

				if (nameA < nameB) {
					return -1;
				}

				if (nameA > nameB) {
					return 1;
				}

				return 0;
				});

				return sortedLanguages;
			} else {
				return this.languages;
			}
		},
		choosedSpeaker() {
			let index = this.speakers.findIndex(item => item.id === this.formData.speaker);
			return this.speakers[index].label || null
		}
	},
	methods: {
		handleScroll() {
			const listExample = this.$refs.listExample;
			const dividerLeft = this.$refs.dividerLeft;
			const dividerRight = this.$refs.dividerRight;

			if (listExample.scrollLeft === 0) {
				dividerLeft.style.opacity = '0';
				dividerRight.style.opacity = '1'; // Tampilkan divider-right
			} else if (listExample.scrollLeft + listExample.clientWidth === listExample.scrollWidth) {
				dividerLeft.style.opacity = '1'; // Tampilkan divider-left
				dividerRight.style.opacity = '0';
			} else {
				dividerLeft.style.opacity = '1';
				dividerRight.style.opacity = '1';
			}
		},
		uploadFile(event) {
			const file = event.target.files[0];
			if (!file) return;

			// Validasi ekstensi file
			const allowedExtensions = ['.mp4', '.mp3', '.webm', '.wav'];
			const fileExtension = file.name.substring(file.name.lastIndexOf('.'));
			let type = null
			if (fileExtension === '.mp3' || fileExtension === '.wav') {
				type = 'audio';
			} else {
				type = 'video'
			}

			if (!allowedExtensions.includes(fileExtension)) {
				this.alertFailIcon('File Extension not allowed');
				return;
			}

			const maxSizeMB = 50;
			const maxSizeBytes = maxSizeMB * 1024 * 1024;

			if (file.size > maxSizeBytes) {
				this.alertFailIcon('File size must less than 50MB');
				return;
			}

			if(type == 'video') {
				const video = document.createElement('video');
				const url = URL.createObjectURL(file);
				video.preload = 'metadata';
				video.onloadedmetadata = function () {
					if(video.duration == Infinity) {
						this.typeUpload = 'infinity'
						this.$emit('duration:type', 'infinity')
						this.$bvModal.show('video-duration-exceeds');
						URL.revokeObjectURL(url);
					} else if (video.duration > 180) { // 180 detik = 3 menit
						this.typeUpload = type
						this.$emit('duration:type', type)
						this.$bvModal.show('video-duration-exceeds');
						URL.revokeObjectURL(url);
					} else {
						const reader = new FileReader();
						reader.onload = () => {
							const fileDataUrl = reader.result;
							this.formData.fileVideo.src = fileDataUrl;
							// this.formData.fileVideo.file = file;
							this.formData.fileVideo.name = file.name;
							this.formData.fileVideo.type = type;
							this.formData.fileVideo.fileSize = file.size;
							// this.generateThumbnail(fileDataUrl);
							this.createVideoChunks(file);
						};
						reader.readAsDataURL(file);
					}
				}.bind(this);
				video.src = url;
			} else if (type === 'audio') {
				const audio = document.createElement('audio');
				const url = URL.createObjectURL(file);
				audio.preload = 'metadata';
				audio.onloadedmetadata = function () {
					if(audio.duration == Infinity) {
						this.typeUpload = 'infinity'
						this.$emit('duration:type', 'infinity')
						this.$bvModal.show('video-duration-exceeds');
						URL.revokeObjectURL(url);
					} else if (audio.duration > 180) { // 180 seconds = 3 minutes
						this.typeUpload = type
						this.$emit('duration:type', type)
						this.$bvModal.show('video-duration-exceeds');
						URL.revokeObjectURL(url);
					} else {
						const reader = new FileReader();
						reader.onload = () => {
							const fileDataUrl = reader.result;
							this.formData.fileVideo.src = fileDataUrl;
							this.formData.fileVideo.file = file;
							this.formData.fileVideo.name = file.name;
							this.formData.fileVideo.type = type;
							this.formData.fileVideo.fileSize = file.size;
							// this.createVideoChunks(file);
						};
						reader.readAsDataURL(file);
					}
				}.bind(this);
				audio.src = url;
			 } else {
				const reader = new FileReader();
				reader.onload = () => {
				const fileDataUrl = reader.result;
					this.formData.fileVideo.src = fileDataUrl
					this.formData.fileVideo.file = file
					this.formData.fileVideo.name = file.name
					this.formData.fileVideo.type = type
					this.formData.fileVideo.fileSize = file.size
					// this.createVideoChunks(file);
				};
				reader.readAsDataURL(file);
			}

		},
		handleDrag(event) {
     		const fileDrag = [...event.dataTransfer.files];
			const file = fileDrag[0];
			if (!file) return;

			// Validasi ekstensi file
			const allowedExtensions = ['.mp4', '.mp3', '.webm', '.wav'];
			const fileExtension = file.name.substring(file.name.lastIndexOf('.'));
			let type = null
			if (fileExtension === '.mp3' || fileExtension === '.wav') {
				type = 'audio';
			} else {
				type = 'video'
			}

			if (!allowedExtensions.includes(fileExtension)) {
				this.alertFailIcon('File Extension not allowed');
				return;
			}

			if (!allowedExtensions.includes(fileExtension)) {
				this.alertFailIcon('File Extension not allowed');
				return;
			}

			const maxSizeMB = 50;
			const maxSizeBytes = maxSizeMB * 1024 * 1024;

			if (file.size > maxSizeBytes) {
				this.alertFailIcon('File size must less than 50MB');
				return;
			}

			if(type == 'video') {
				const video = document.createElement('video');
				const url = URL.createObjectURL(file);
				video.preload = 'metadata';
				video.onloadedmetadata = function () {
					if(video.duration == Infinity) {
						this.typeUpload = 'infinity'
						this.$emit('duration:type', 'infinity')
						this.$bvModal.show('video-duration-exceeds');
						URL.revokeObjectURL(url);
					} else if (video.duration > 180) { // 180 detik = 3 menit
						this.typeUpload = type
						this.$emit('duration:type', type)
						this.$bvModal.show('video-duration-exceeds');
						URL.revokeObjectURL(url);
					} else {
						const reader = new FileReader();
						reader.onload = () => {
							const fileDataUrl = reader.result;
							this.formData.fileVideo.src = fileDataUrl;
							// this.formData.fileVideo.file = file;
							this.formData.fileVideo.name = file.name;
							this.formData.fileVideo.type = type;
							this.formData.fileVideo.fileSize = file.size;
							// this.generateThumbnail(fileDataUrl);
							this.createVideoChunks(file);
						};
						reader.readAsDataURL(file);
					}
				}.bind(this);
				video.src = url;
			} else if (type === 'audio') {
				const audio = document.createElement('audio');
				const url = URL.createObjectURL(file);
				audio.preload = 'metadata';
				audio.onloadedmetadata = function () {
					if(audio.duration == Infinity) {
						this.typeUpload = 'infinity'
						this.$emit('duration:type', 'infinity')
						this.$bvModal.show('video-duration-exceeds');
						URL.revokeObjectURL(url);
					} else if (audio.duration > 180) { // 180 seconds = 3 minutes
						this.typeUpload = type
						this.$emit('duration:type', type)
						this.$bvModal.show('video-duration-exceeds');
						URL.revokeObjectURL(url);
					} else {
						const reader = new FileReader();
						reader.onload = () => {
							const fileDataUrl = reader.result;
							this.formData.fileVideo.src = fileDataUrl;
							this.formData.fileVideo.file = file;
							this.formData.fileVideo.name = file.name;
							this.formData.fileVideo.type = type;
							this.formData.fileVideo.fileSize = file.size;
							// this.createVideoChunks(file);
						};
						reader.readAsDataURL(file);
					}
				}.bind(this);
				audio.src = url;
			 } else {
				const reader = new FileReader();
				reader.onload = () => {
				const fileDataUrl = reader.result;
					this.formData.fileVideo.src = fileDataUrl
					this.formData.fileVideo.file = file
					this.formData.fileVideo.name = file.name
					this.formData.fileVideo.type = type
					this.formData.fileVideo.fileSize = file.size
					// this.createVideoChunks(file);
				};
				reader.readAsDataURL(file);
			}
		},
		// async uploadVideo(file) {
			// console.log(`Ukuran file asli: ${file.size} bytes`);
			// const ffmpegModule = await ffmpeg.createFFmpeg();
			// await ffmpegModule.load();
			// await ffmpegModule.FS('writeFile', 'input.mp4', new Uint8Array(await file.arrayBuffer()));
			// await ffmpegModule.run('-i', 'input.mp4', '-vf', 'scale=640:480', 'output.mp4');

			// const outputData = ffmpegModule.FS('readFile', 'output.mp4');
			// const compressedVideoBlob = new Blob([outputData.buffer], { type: 'video/mp4' });

			// console.log(`Ukuran file setelah kompresi: ${compressedVideoBlob.size} bytes`);
			// this.createVideoChunks(compressedVideoBlob);
		// },
		createVideoChunks(file) {
			const chunkSize = 4 * 1024 * 1024;
			this.formData.fileVideo.file = [];

			for (let start = 0; start < file.size; start += chunkSize) {
				const chunk = file.slice(start, start + chunkSize);
				this.formData.fileVideo.file.push(chunk);
			}

			// const reader = new FileReader();
			// let offset = 0;
			// const videoChunks = [];

			// reader.onload = () => {
			// 	const chunk = reader.result;
			// 	videoChunks.push(chunk);

			// 	offset += chunkSize;
			// 	if (offset < file.size) {
			// 		readNextChunk();
			// 	} else {
			// 		const blob = new Blob(videoChunks, { type: file.type });
			// 		this.formData.fileVideo.file = [];
      		// 		this.formData.fileVideo.file.push(blob);
			// 	}
			// };

			// function readNextChunk() {
			// 	const slice = file.slice(offset, offset + chunkSize);
			// 	reader.readAsArrayBuffer(slice);
			// }

			// readNextChunk();
		},
		generateThumbnail(src) {
			const videoElement = this.$refs.videoElement;
			videoElement.src = src;

			videoElement.addEventListener('loadeddata', () => {
				// Capture the first frame as a thumbnail
				const canvas = document.createElement('canvas');
				canvas.width = videoElement.videoWidth;
				canvas.height = videoElement.videoHeight;
				canvas.getContext('2d').drawImage(videoElement, 0, 0, canvas.width, canvas.height);
				const thumbnailDataUrl = canvas.toDataURL('image/jpeg'); // You can adjust the format as needed

				this.formData.fileVideo.thumbnail = thumbnailDataUrl;
			});
		},
		deleteOriginalSrtFile() {
			this.formData.fileSrt1.file = null
			this.formData.fileSrt1.fileName = null
			this.formData.fileSrt1.lang = null
		},
		deleteTanslatedSrtFile() {
			this.formData.fileSrt2.file = null
			this.formData.fileSrt2.fileName = null
			this.formData.fileSrt2.lang = null
		},
		toggleDropdownLang() {
			if (this.$refs.buttonLangTarget) {
				setTimeout(() => {
					this.$refs.buttonLangTarget.click();
				}, 100)
			}
		},

	},
	mounted() {
		const listExample = this.$refs.listExample;
    	listExample.addEventListener('scroll', this.handleScroll);
		this.handleScroll();
	},
	beforeDestroy() {
		const listExample = this.$refs.listExample;
		listExample.removeEventListener('scroll', this.handleScroll);
	},
}
</script>

<style scoped>
.ad-uc {
	padding: 2px;
	background-image: linear-gradient(to bottom right, #FFFFFF33, #FFFFFF11);
	border-radius: 8px;
}

.ad-uc__inner {
	background: linear-gradient(0deg, #121418 0%, #121418 100%), rgba(49, 48, 54, 0.30);
	border-radius: 8px;
	backdrop-filter: blur(2px);
	gap: 16px;
	padding-top: 22px;
	padding-bottom: 22px;
}

.cxad-uc__inner--padding {
	padding-left: 22px;
	padding-right: 22px;
}

.ad-uc__icon-info {
	right: 6px;
	top: 6px;
}

.ad-uc__info--inner {
	left: 0;
	width: 350px;
	border-radius: 6px;
	background-color: #1F1F1F;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);

	visibility: hidden;
	opacity: 0;
	transition: opacity 0.25s;
}

.ad-uc__icon-info:hover .ad-uc__info--inner {
	visibility: visible;
	opacity: 1;
}

.ad-uc__info--title, .ad-uc__info--inner ul li {
	font-size: 10px;
}

.ad-uc__info--title {
	font-weight: 600;
}

.ad-uc__label {
	border-radius: 10px;
	border: 1px dashed #2D2D2D;
	background-color: #000;
	gap: 12px;
	cursor: pointer;
    height: 174px;
	transition: background-color 0.25s;
}

.ad-uc__label:hover {
	background-color: #0e0e0e;
}

.ad-uc__label-text1 {
	color: #6D75F6;
	font-size: 15px;
	font-weight: 500;
}

.ad-uc__label-text2, .ad-uc__label-text3 {
	color: #979797;
	font-weight: 400;
}

.ad-uc__label-text2 {
	font-size: 10px;
}

.ad-uc__label-text3 {
	font-size: 12px;
}

.ad-uc__drop-wrap {
	border-radius: 4px;
	border: 1px solid #2D2D2D;
}

.ad-uc__drop-wrap--text {
	color: #FFF;
	font-size: 14px;
	font-weight: 400;
}

.btn-choose {
	font-size: 12px;
	color: #fff;
	font-weight: 400;
}

.dropdown-menu {
	min-width: 8rem;
	border-radius: 6px;
	background: #1F1F1F;
	box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.85);
}

.dropdown-menu-lang {
	width: 12rem;
}

.select-lang {
  width: 100% !important;
  height: 12rem;
  padding: 10px 10px;
}

.select-lang::-webkit-scrollbar {
  width: 3px;
}

.select-lang::-webkit-scrollbar-track {
  background: #636363;
  border-radius: 4px;
}

.select-lang::-webkit-scrollbar-thumb {
  background: #B6B6B6;
  border-radius: 4px;
  background-clip: padding-box;
}

.select-lang::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.ad-uc__label-check {
	color: #FFF;
	font-size: 10px;
	font-weight: 400;
}

.ad-uc__list-example {
	overflow-x: auto;
	gap: 12px;
	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
}

.ad-uc__list-example > *:first-child {
	margin-left: 22px;
}

.ad-uc__list-example > *:last-child {
	margin-right: 22px;
}

.ad-uc__list-example::-webkit-scrollbar {
  display: none;
}

.ad-uc__card-video {
	min-width: 207px;
	max-width: 207px;
	height: 116px;
	border-radius: 6px;
}

.ad-uc__card-img {
	width: 100%;
	height: 100%;
	object-fit: cover;
    object-position: center center;
	border-radius: 6px;
}

.button--srt {
	gap: 6px;
}

.divider-left, .divider-right {
	opacity: 0;
	transition: opacity 0.3s ease;
}

.divider-right {
    top: 0;
    right: -2px;
    width: 74px;
    height: 100%;
    /* transform: rotate(270deg); */
    position: absolute;
    background: linear-gradient(88deg, rgba(18, 20, 24, 0.00) 6.25%, #121418 100%);
}

.divider-left {
	top: 0;
    left: -1px;
    width: 74px;
    height: 100%;
    position: absolute;
    /* transform: rotate(90deg); */
    background: linear-gradient(273deg, rgba(18, 20, 24, 0.00) 6.25%, #121418 100%);
}

.ad-uc__srt--wrap {
	padding: 6px;
	background-color: #1f1f1f;
	color: #fff;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	border-radius: 6px;
    width: 100%;
}

.btn--delete-file {
	padding: 2px;
	transition: background-color 0.25s;
}

.btn--delete-file:hover {
	background-color: #383838;
}
</style>

<style>
.ad-uc__check .vs-checkbox {
	border: 2px solid white !important;
	background-color: transparent;
	border-radius: 4px;
	width: 16px;
	height: 16px;
}
.ad-uc__check .vs-checkbox--input:checked+.vs-checkbox {
	border: 2px solid rgb(109, 117, 246) !important;
}

.ad-uc__check .con-vs-checkbox {
	margin-left: 0px;
}

.ad-uc__check .vs-checkbox--icon {
	font-size: 12px;
}

.video__wrapper {
	height: 140px;
	border-radius: 10px;
}

.video__wrapper--video {
	width: 100%;
	height: 100%;
	border-radius: 10px;
}

.t__action-play {
    width: 100%;
    position: absolute;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.t__toggle {
  background: none;
  /* filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.20)); */
  /* filter: drop-shadow(13px 18px 14px #000); */
  filter: drop-shadow(13px 7px 14px #000);
}

.t__toggle-icon {
  font-size: 26px;
  color: #FFFFFF;
}
</style>